export const NodeType = {
  Root: {
    type: 'root',
    tag: 'div',
  },
  Heading: {
    type: 'heading',
    tag: 'h1',
  },
  List: {
    type: 'list',
    tag: 'ul',
  },
  Text: {
    type: 'text',
    tag: 'span',
  },
  Quote: {
    type: 'quote',
    tag: 'blockquote',
  },
  Paragraph: {
    type: 'paragraph',
    tag: 'p',
  },
  ListItem: {
    type: 'listitem',
    tag: 'li',
  },
  Link: {
    type: 'link',
    tag: 'a',
  },
  AutoLink: {
    type: 'autolink',
    tag: 'a',
  },
  Upload: {
    type: 'upload',
    tag: 'div',
  },
};

export const NodeTypeTag = {
  root: 'div',
  heading: 'h1',
  list: 'ul',
  text: 'span',
  quote: 'blockquote',
  paragraph: 'p',
  listitem: 'li',
  link: 'a',
  upload: 'div',
  div: 'div',
  image: 'img',
  video: 'video',
  audio: 'audio',
  bold: 'strong',
  italic: 'em',
  strikethrough: 's',
  underline: 'u',
  code: 'code',
  subscript: 'sub',
  superscript: 'sup',
  highlight: 'mark',
};
