<template>
  <div class="text-content" :class="`columns-${columns}`">
    <div v-if="title" class="text-content__title">
      {{ title }}
    </div>
    <div class="text-content__columns">
      <div
        v-for="(contentHTML, tilesIndex) in renderedContentsHTML"
        :key="`text-content-${tilesIndex}`"
        v-html="contentHTML"
        class="text-content__columns__text"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from '@nuxtjs/composition-api';
import { serializeToHtml } from '~/helpers/cms/lexicalConverter';

export default defineComponent({
  name: 'TextContent',
  props: {
    blockData: {
      type: Object,
      required: true,
      validator: (value) =>
        !!value.contentFirstColumn_html || value.contentFirstColumn_html === '',
    },
  },
  setup(props) {
    const { blockData } = props;
    const renderedContentsHTML = ref([]);
    const title = ref(blockData?.title || '');
    const columns = ref(blockData?.columns || '1');

    onMounted(() => {
      const contentsHTML = [];
      try {
        const renderedFirstColumnHTML =
          blockData?.contentFirstColumn?.root &&
          serializeToHtml(blockData.contentFirstColumn.root);
        contentsHTML.push(renderedFirstColumnHTML);
      } catch (error) {
        console.error('unable to render text content:', error);
        contentsHTML.push('');
      }
      if (blockData?.columns === '2') {
        try {
          const renderedSecondColumnHTML =
            blockData?.contentSecondColumn?.root &&
            serializeToHtml(blockData.contentSecondColumn.root);
          contentsHTML.push(renderedSecondColumnHTML);
        } catch (error) {
          console.error('unable to render text content:', error);
        }
      }
      renderedContentsHTML.value = contentsHTML;
    });

    return {
      title,
      columns,
      renderedContentsHTML,
    };
  },
});
</script>

<style lang="scss" scoped>
.text-content {
  @include desktop-boxed-inner;
  margin: 5rem auto;
  &__title {
    margin-bottom: 1.25rem;
    @include h2;
  }
  &__columns {
    &__text {
      @include text-16-regular-light_black;
      line-height: var(--line-height-26);
    }
  }
  &.columns-1 {
    text-align: center;
    max-width: 45rem;
  }
  &.columns-2 {
    .text-content__title {
      @include h3;
      color: var(--c-primary);
    }
    .text-content__columns {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1.875rem;
    }
  }
  @include to-tablet-max {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    margin: 3rem auto;
    &__title {
      @include h3;
      color: var(--c-primary);
    }
    &.columns-2 {
      .text-content__columns {
        display: flex;
        flex-direction: column;
        gap: 1.25rem;
      }
    }
  }
}
</style>
