/* eslint-disable no-bitwise */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { NodeType, NodeTypeTag } from './lexicalTypes';

const renderText = (node) => {
  const formats = {
    1: NodeTypeTag.bold,
    2: NodeTypeTag.italic,
    4: NodeTypeTag.strikethrough,
    8: NodeTypeTag.underline,
    16: NodeTypeTag.code,
    32: NodeTypeTag.subscript,
    64: NodeTypeTag.superscript,
    128: NodeTypeTag.highlight,
  };

  return Object.keys(formats).reduce((formattedText, format) => {
    if (node.format & Number(format)) {
      const tag = formats[format];
      return `<${tag}>${formattedText}</${tag}>`;
    }
    return formattedText;
  }, node.text);
};

const renderAlignment = (format) => (['left', 'center', 'right', 'justify'].includes(format) ? `text-align: ${format}` : '');
const renderSource = (string) => (string ? `src="${string}"` : ``);
const renderHref = (string) => (string ? `href="${string}"` : ``);
const renderStyle = (string) => (string ? `style="${string}"` : ``);
const createHtmlTag = (tag, attributes = '', content = '') => `<${tag}${attributes ? ` ${attributes}` : ''}>${content}</${tag}>`;

const renderUpload = (node, alignment) => {
  const { url, mimeType, alt, filename } = node.value;
  const altText = alt || filename;
  const src = `src="${renderSource(url)}"`;
  const href = `href="${renderHref(url)}" download="${filename}"`;

  const tags = {
    'image/jpeg': createHtmlTag(NodeTypeTag.image, `${src} alt="${alt}"`),
    'image/png': createHtmlTag(NodeTypeTag.image, `${src} alt="${alt}"`),
    'image/gif': createHtmlTag(NodeTypeTag.image, `${src} alt="${alt}"`),
    'video/mp4': createHtmlTag(NodeTypeTag.video, src, 'controls'),
    'audio/mpeg': createHtmlTag(NodeTypeTag.audio, src, 'controls'),
    'application/pdf': createHtmlTag(NodeTypeTag.link, href, altText),
    default: createHtmlTag(NodeTypeTag.link, href, altText),
  };

  return alignment ? `<${NodeTypeTag.div} ${alignment}>${tags[mimeType] || tags.default}</${NodeTypeTag.div}>` : tags[mimeType] || tags.default;
};

const STYLE_ALIGN_LIST = '; display: table; margin: 0 auto;';

const generateNodeData = (node) => {
  try {
    return {
      tag:
        node?.tag ||
        Object.values(NodeType).find((type) => {
          try {
            return type.type === node.type;
          } catch {
            console.error('🚀 ~ tag:node.tag||Object.values ~ error:', type);
            console.error('🚀 ~ tag:node.tag||Object.values ~ error:', node);
          }
          return false;
        })?.tag,
      format: node.format,
      children: node.children,
      alignment: renderAlignment(node.format),
      text: renderText(node),
      url: node.fields?.url,
      type: node.type,
      alignmentStyle: renderStyle(renderAlignment(node.format)),
      href: renderHref(node.fields?.url),
    };
  } catch (error) {
    console.error('🚀 ~ generateNodeData ~ error', error);
  }
  return {};
};

export const serializeToHtml = (serializedNodes) => {
  const renderNode = (node) => {
    const { type, tag, format, alignmentStyle, alignment, href, children, text } = generateNodeData(node);
    const recursedNodes = children?.map(renderNode).join('');

    switch (type) {
      case NodeType.Root.type: {
        return recursedNodes;
      }
      case NodeType.Heading.type:
      case NodeType.Text.type:
      case NodeType.Quote.type:
      case NodeType.List.type:
      case NodeType.Paragraph.type: {
        return createHtmlTag(tag, format ? alignmentStyle : '', recursedNodes || text);
      }
      case NodeType.ListItem.type: {
        const list = createHtmlTag(tag, '', recursedNodes || text);
        return format ? createHtmlTag(NodeTypeTag.div, renderStyle(`${renderAlignment(format)}${STYLE_ALIGN_LIST}`), list) : list;
      }
      case NodeType.AutoLink.type:
      case NodeType.Link.type: {
        const anchor = createHtmlTag(tag, href, recursedNodes);
        return format ? createHtmlTag(tag, alignmentStyle, anchor) : anchor;
      }
      case NodeType.Upload.type: {
        return renderUpload(node, alignment);
      }
      default: {
        console.error('lexicalConverter - unknown type', type);
        return '';
      }
    }
  };
  return renderNode(serializedNodes);
};
